/* Fonts */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Poppins"),
         url("./fonts/poppins-v15-latin-300.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* poppins-regular - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Poppins"),
         url("./fonts/Poppins-Regular.ttf") format("truetype");
  }
  /* poppins-600 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local("Poppins"),
         url("./fonts/poppins-v15-latin-600.woff2") format("woff2"),
         url("./fonts/poppins-v15-latin-600.woff") format("woff");
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Poppins"),
         url("./fonts/poppins-v15-latin-700.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* poppins-800 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local("Poppins"),
    url("./fonts/poppins-v15-latin-800.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  